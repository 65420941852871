/* eslint-disable */
import React, { useState, useEffect } from "react";
import "./index.css";

function App() {
  const darkColors = ["#3a0a5b", "#130060", "#0c3934", "#000000"];
  const [bgColor, setBgColor] = useState(darkColors[0]);

  const handleDoubleClick = () => {
    setBgColor((prevColor) => {
      const currentIndex = darkColors.indexOf(prevColor);
      const nextIndex = (currentIndex + 1) % darkColors.length;
      return darkColors[nextIndex];
    });
  };

  useEffect(() => {
    const setRandomColor = () => {
      const randomIndex = Math.floor(Math.random() * darkColors.length);
      setBgColor(darkColors[randomIndex]);
    };
    setRandomColor();
  }, []);

  return (
    <div
      className="min-h-screen text-white jak"
      style={{ backgroundColor: bgColor }}
      onDoubleClick={handleDoubleClick}
    >
      <div className="max-w-3xl mx-auto lg:py-24 md:py-20 py-16 px-4">
        <header className="mb-8">
          <img
            src="https://github.com/kartikmehta8.png"
            alt="mehta"
            className="w-32 h-32 sm:w-40 sm:h-40 lg:w-48 lg:h-48 rounded-full mb-4"
          />
          <div className="lg:text-5xl md:text-5xl text-4xl">kartik mehta</div>
          <p className="lg:text-2xl md:text-2xl text-xl text-gray-300">
            engineer / passionate about improving developer experience
          </p>
        </header>

        <section className="mb-8 lg:text-2xl md:text-xl text-lg">
          <h2 className="lg:text-2xl md:text-2xl text-xl mb-4 underline">
            about me
          </h2>
          <p className="mb-4">
            i am the kind of programmer who’ll gladly spend 10 hours automating
            a task I could do in 10 minutes, haha.
          </p>
          <p className="mb-4">
            i have a habit of jumping between tech stacks, roles, and
            locations—because, you know, remote work really blurs the line
            between "working" and "pretending not to."
          </p>
          <p className="mb-4">
            i never leave without my laptop—because you never know when
            inspiration (or a production issue) might strike.
          </p>
          <p>
            need proof that I actually do stuff? check out my{" "}
            <a
              href="https://drive.google.com/file/d/17uesQIRe5-oICgWr8owlohqaNn9c6ou9/view"
              target="_blank"
              rel="noopener noreferrer"
              className="italic text-indigo-300 hover:text-indigo-500"
            >
              resume.
            </a>
          </p>
        </section>

        <section className="mb-8 lg:text-xl md:text-xl text-lg">
          <h2 className="lg:text-2xl md:text-2xl text-xl mb-4 underline">
            my products
          </h2>
          <div className="lg:text-2xl md:text-xl text-lg inline-block">
            <a
              className="italic text-indigo-300 hover:text-indigo-500"
              href="https://orion.mrmehta.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              orion
            </a>{" "}
            //{" "}
            <a
              className="italic text-indigo-300 hover:text-indigo-500"
              href="https://www.tailwine.in"
              target="_blank"
              rel="noopener noreferrer"
            >
              tailwine
            </a>
          </div>
        </section>

        <footer>
          <h2 className="lg:text-2xl md:text-2xl text-xl mb-4 underline">
            my socials
          </h2>
          <p className="mb-4 lg:text-2xl md:text-xl text-lg">
            <a
              href="https://x.com/kartik_mehta8"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              x
            </a>{" "}
            //{" "}
            <a
              href="https://github.com/kartikmehta8"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              github
            </a>{" "}
            //{" "}
            <a
              href="https://www.linkedin.com/in/kartikmehta17"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              linkedin
            </a>{" "}
            //{" "}
            <a
              href="https://writer.mrmehta.in"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              hashnode
            </a>{" "}
            //{" "}
            <a
              href="https://connect.mrmehta.in"
              className="italic text-indigo-300 hover:text-indigo-500"
              target="_blank"
              rel="noopener noreferrer"
            >
              all
            </a>
          </p>
        </footer>
      </div>
    </div>
  );
}

export default App;
